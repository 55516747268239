

































import { Component, Prop, Vue } from 'vue-property-decorator'
import { format } from 'quasar'

const { capitalize } = format

@Component({
  name: 'IntegrationManagementSectionControlButtons',
  components: {
    IconTrash: () => import('@/components/UI/icons/IconTrash.vue'),
  },
})
export default class IntegrationManagementSectionControlButtons extends Vue {
  @Prop({
    type: Boolean,
    default: false,
  }) readonly isNew!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly loading!: boolean

  @Prop({
    type: Boolean,
    default: false,
  }) readonly canSave!: boolean

  get saveBtnLabel (): string {
    const key = this.isNew ? 'common.create' : 'common.save'
    return capitalize(this.$t(key).toString())
  }
}
